#orbit-system {
  position: relative;
  width: 18em;
  height: 18em;
  margin: 0 auto;
  scale: 1.2;
  transform: translate(300px, -50px);
  -webkit-transform: translate(300px, -50px);
}

.system {
  position: relative;
  width: 100%;
  height: 100%;

  -webkit-transform: rotateX(80deg) rotateY(-30deg);
  transform: rotateX(70deg) rotateY(-15deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.planet,
.satellite-orbit,
.satellite-orbit2,
.satellite-orbit3,
.satellite-orbit4,
.satellite,
.satellite3,
.satellite2,
.satellite4,
.satelite22,
.satellite33,
.satellite44 {
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.planet {
  width: 7em;
  margin-top: -8.5em;
  margin-left: -7.5em;
  background-size: cover;
  border-radius: 50%;
  color: white;
  line-height: 9em;

  -webkit-transform: rotateY(30deg) rotateX(-75deg);
  transform: rotateY(30deg) rotateX(-75deg);

  .planet-image {
    width: 170px;
    height: 170px;
    position: relative;
    left: 25px;
    bottom: 22px;

  }
}

.planet-shadow{
  position: absolute;
  z-index: -1;
  left: -30px;
  bottom: -40%;
}

.satellite-orbit {
  width: 16em;
  height: 16em;
  margin-top: -8em;
  margin-left: -8em;
  border: 3px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;

  -webkit-animation-name: webkit-orbit;
  animation-name: orbit;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.satellite-orbit2 {
  width: 27em;
  height: 27em;
  margin-top: -10em;
  margin-left: -13em;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.satellite3 {
  top: 100%;
  // width: 3em;
  height: 3em;
  margin-top: -4.5em;
  margin-left: 8.5em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/dyptool3.png");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  -webkit-animation-name: webkit-reverse-orbit;
  animation-name: reverse-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.satellite {
  top: 100%;
  // width: 3em;
  height: 3em;
  margin-top: -2.5em;
  margin-left: -7.5em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/launchpad.png");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  -webkit-animation-name: webkit-reverse-orbit;
  animation-name: reverse-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-direction: initial;
}

.satellite33 {
  top: 93%;
  height: 50px;
  margin-top: -28.5em;
  margin-left: -19.5em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/bridgeSatellite.png");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  -webkit-animation-name: webkit-reverse-orbit;
  animation-name: reverse-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-direction: initial;
  -webkit-animation-direction: initial;
}

.satellite-orbit3 {
  width: 40em;
  height: 40em;
  margin-top: -14em;
  margin-left: -19em;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;

  -webkit-animation-name: webkit-orbit-reverse;
  animation-name: orbit-reverse;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.satellite4 {
  top: 100%;
  height: 50px;
  margin-top: -36.5em;
  margin-left: 23.5em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/earn.png");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  -webkit-animation-name: webkit-invert-orbit;
  animation-name: invert-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-direction: normal;
  -webkit-animation-direction: normal;
}

.satellite44 {
  top: 90%;
  left: 19%;
  height: 3em;
  margin-top: -1.5em;
  margin-left: -1.5em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/governanceSatellite.png");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  -webkit-animation-name: webkit-invert-orbit;
  animation-name: invert-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-direction: normal;
  -webkit-animation-direction: normal;
}

.satellite2 {
  top: 100%;
  // width: 3em;
  height: 50px;
  margin-top: -2.5em;
  margin-left: -8.5em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/metaverse.png");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  -webkit-animation-name: webkit-invert-orbit;
  animation-name: invert-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-direction: normal;
  -webkit-animation-direction: normal;
}

.satellite22 {
  top: 100%;
  // width: 3em;
  height: 50px;
  margin-top: 0em;
  margin-left: 16em;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  text-align: center;
  line-height: 3em;
  background-image: url("https://cdn.worldofdypians.com/dypius/caws.svg");
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  -webkit-animation-name: webkit-invert-orbit;
  animation-name: invert-orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-direction: normal;
  -webkit-animation-direction: normal;
}

.satellite-orbit4 {
  width: 53em;
  height: 53em;
  margin-top: -18em;
  margin-left: -25em;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;

  -webkit-animation-name: webkit-orbit;
  animation-name: orbit;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes webkit-orbit {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
  }
}

@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
  }
}

@keyframes orbit-reverse {
  0% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}

@-webkit-keyframes webkit-orbit-reverse {
  0% {
    -webkit-transform: rotateZ(360deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
  }
}

/*
  In order to invert the rotation, do the rotate* transforms in reverse order.
  
  Here, we rotated the system with rotateX(75deg) rotateY(-30deg), and then we are animating the rotation of the orbit with rotateZ(0-360deg). To reverse that, we need to reverse the last transform applied (rotateZ), then rotateY, then rotateX.
  
  The rotateX/Y reversal is easy since it's not animated, so just use its opposite (30deg, -75deg). The rotateZ is trickier since it's animating, but since it's rotating from 0 to 360, we just need to animate the satellite from 360 to 0.
  */
@-webkit-keyframes webkit-invert-orbit {
  0% {
    scale: 1.5;

    -webkit-transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
  }

  100% {
    -webkit-transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);

    scale: 1.5;
  }
}

@keyframes invert-orbit {
  0% {
    scale: 1;
    transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
    -webkit-transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
  }

  100% {
    transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
    -webkit-transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);

    scale: 1;
  }
}

@keyframes reverse-orbit {
  0% {
    scale: 1;
    transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
    -webkit-transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
  }

  100% {
    transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
    -webkit-transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
    scale: 1;
  }
}

@-webkit-keyframes webkit-reverse-orbit {
  0% {
    scale: 1;
    -webkit-transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
  }

  100% {
    -webkit-transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);

    scale: 1;
  }
}

// .satellite-orbit3:hover, .satellite-orbit2:hover, .satellite-orbit:hover, .satellite-orbit4:hover  {
//   animation-play-state:paused;

//   .satellite3, .satellite2, .satellite, .satellite22, .satellite4, .satellite33, .satellite44 {
//   animation-play-state:paused;

//   }
// }

@media only screen and (max-width: 1200px) {
  #orbit-system {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 991px) {
  #orbit-system {
    transform: translate(150px, -50px) !important;
    -webkit-transform: translate(150px, -50px) !important;
  }
}

@media only screen and (max-width: 500px) {
  #orbit-system {
    scale: 0.8 !important;
    transform: translate(110px, -50px) !important;
    -webkit-transform: translate(110px, -50px) !important;
  }
}
