.contact-wrapper {
  position: relative;
  min-height: 800px;
  padding-bottom: 3rem;
  background-image: url('https://cdn.worldofdypians.com/dypius/contactUsBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  
  .contact-container {
    .outer-form {
      background: #ffffff;
      border: 4px solid #eff0f6;
      box-shadow: 0px 16px 32px rgba(118, 111, 223, 0.12);
      border-radius: 28px 0px;
      min-height: 700px;
      z-index: 1;

      h2 {
        color: #7670e0;
      }

      .titlewrapper {
        height: 40px;

        @media only screen and (max-width: 991px) {
          height: auto;
        }
      }

      .form-container {
        background: #ffffff;
        border: 2px solid #eff0f6;
        border-radius: 24px;
        min-height: 400px;

        .label {
          background: white;
          left: 5px;
          top: 10px;
          // display: none;
          transition: all 0.25s;
          -webkit-transition: all 0.25s;

          // z-index: -1;
        }

        .label-focus {
          font-size: 11px;
          left: 5px;
          top: -5px;
        }

        .contact-input {
          border-radius: 8px;
          border: 1px solid #a0a3bd;
          z-index: 2;
        }

        .contact-input:focus {
          border: 1px solid #a0a3bd !important;
          outline: none;
        }
      }

      .contactimg {
        position: relative;
        top: -55px;

        @media only screen and (max-width: 991px) {
          top: auto;
        }
      }

      .custom-file-input {
        color: transparent;

      }

      .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
      }

      .custom-file-input::-moz-file-upload-button {
        visibility: hidden;
      }

      input::file-selector-button  {
        visibility: hidden !important;
      }

      .custom-file-input::before {
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        height: 35px;
        width: 100%;

        color: black;
        display: inline-block;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
      }
    }
  }
}

.contact-wrapper::before {
  content: "";
  position: absolute;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.business-content{
  margin-bottom: 7rem;
  @media screen and (max-width: 786px) {
    margin-bottom: 1rem;
  }
}

.submitbtn {
  width: 23%;

  @media only screen and (max-width: 970px) {
    width: 100%;

    .outline-btn, .custom-file-input {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 970px) {
  .outer-form {
    .outline-btn {
      width: 100% !important;
    }
  }

  // .contact-wrapper{
  //   margin: 0px 15px;
  // }
}

.helpertext {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #a0a3bd;
}
