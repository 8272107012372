.why-dypius {
  content: "";
  min-height: 900px;
  width: 100%;
  background: #eff0f6;
  background-image:
   url("https://cdn.worldofdypians.com/dypius/whyDypiusBackground.png"),
    linear-gradient(0deg, #eff0f6 0%, rgba(239, 240, 246, 0) 73.43%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 0 0 56px 56px;
  position: relative;
  // z-index: -1;
  bottom: 60px;

  .orange-border {
    position: absolute;
    width: 470px;
    height: 403px;
    left: -35%;
  }

  .orange-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background: linear-gradient(280.88deg, #f1643e 9.02%, #f78057 90.66%);
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 28px 0px;
    width: 320px;
    height: 250px;

    .divider {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      z-index: 1;
      height: 125px;
      width: 125px;
    }

    .child-1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .child-2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .child-3 {
      grid-area: 2 / 1 / 3 / 2;
    }
    .child-4 {
      grid-area: 2 / 2 / 3 / 3;
    }
  }


  .purple-background{
    position: absolute;
    height: 550px;
    width: 450px;
    left: -32%;
    top: -65%;
    }

    .why-sphere{
      position: absolute;
    z-index: 6;
    width: 150px;
    right: 0;
    bottom: -35%;
    }

  .purple-card {
    background: linear-gradient(280.88deg, #554fd8 9.02%, #7770e0 90.66%);
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 28px 0px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
    z-index: 5;

    
  }

  .card-wrapper {
    height: 900px;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
    top: 150px;

    .title-container {
      position: absolute;
      left: 15%;
    }

    .why-card {
      background: #ffffff;
      box-shadow: 0px 10px 10px rgba(17, 17, 17, 0.08);
      border-radius: 30px 0px 18px;
      height: 220px;
      flex-shrink: 0;

      h5 {
        font-size: 21px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }

      .green-tag {
        height: 30px;
        width: 30px;
        background: #75cac2;
        border-radius: 12px 0px;
        position: absolute;
        top: -6%;
        left: 7%;
      }

      .tag {
        width: 50px;
        height: 50px;
        background: #7770e0;
        // box-shadow: 0px 10px 12px rgba(119, 112, 224, 0.24);
        border-radius: 18px 0px;
        position: absolute;
        top: 0%;
        left: 0%;
      }
    }

    .why-card:nth-child(even) {
      margin-top: 50px !important;
    }

    .card-container {
      height: 900px;
      overflow-y: scroll;
      padding-right: 200px;
      padding-bottom: 20px;
      margin-top: 200px;
    }

    .ring {
      position: absolute;
      right: 4%;
    }

    .slick-list {
      width: 600px !important;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}


@media screen and (max-width: 1400px) {
  .why-dypius{
    margin-bottom: 100px;
   .card-wrapper{
    .why-card{
      h5{
        font-size: 19px;
      }
      p{
        font-size: 12px;
      }
    }
   }
  }
  
}


@media screen and (max-width: 991px) {
  .why-dypius{
    .purple-card{
      width: 320px;
    }
    .purple-background{
      top: -47%;
    }
  }
}

  



@media screen and (max-width: 786px) {
  .why-dypius {
    padding-bottom: 20px;
    .card-wrapper {
      height: auto;
      grid-template-columns: auto;
      .title-container {
        position: initial;
      }
      .why-card {
        width: auto;
        height: 330px;
        border-radius: 81px 0px 18px;
        p{
          font-size: 14px;
        }

        .tag {
          top: 2%;
          left: 3%;
        }
        .green-tag {
          top: -2%;
          left: 12%;
        }
      }

      .why-card:nth-child(even) {
        margin-top: 0px !important;
      }
      .ring {
        right: -20%;
        top: 55%;
      }
    }
  }

  .orange-border {
    left: -30% !important;
    top: -13px !important;
  }
  .purple-background{
    height: 400px !important;
    width: 450px !important;
    left: -29% !important;
    top: -23% !important;
  }
}
