.dyp-main-hero {
  min-height: 500px;
  background: linear-gradient(121.83deg, #554fd8 14.64%, #7770e0 85.55%);
  border-radius: 0 0 56px 56px;

  p {
    font-weight: 300;
    letter-spacing: 0.0075em;
  }

 


  .coin:nth-child(2){
    animation: rotate 10s linear infinite;
    -webkit-animation: rotate 10s linear infinite;

  }

  @keyframes rotate {
    from {
      -ransform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .coin {
    position: absolute;
    height: 300px;
    width: 300px;
    bottom: -5%;
    right: 8%;
  }

  .waves {
    width: 300px;
    height: 300px;
  }

  .waves:first-child {
    position: absolute;
    top: 0;
    right: 0;
  }

  .waves:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .mobile-waves {
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: 150px;
    left: -60px;
    opacity: 0.7;
  }

  .ring {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 12%;
    left: 22%;
    filter: blur(2px) drop-shadow(0px 34px 34px rgba(31, 27, 103, 0.34));
  }

  .submenu-link {
    display: flex;
    justify-content: center;
    width: 140px;
    gap: 9px;
    font-size: 15px;

    @media screen and (max-width: 786px) {
      justify-content: space-between;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
    }
  
    50% {
      -webkit-transform: scale(1.4);
    }
  
    100% {
      -webkit-transform: scale(1);
    }
  }

@media screen and (max-width: 1400px) {
  .dyp-main-hero {
    .coin {
      right: -6%;
    }
  }
}
@media screen and (max-width: 1199px) {
  .dyp-main-hero {
    .coin {
      right: -25%;
    }
  }
}
@media screen and (max-width: 991px) {
  .dyp-main-hero {
    .coin {
      right: 0%;
    }
    .background {
      left: 42%;
    }
  }
}

@media screen and (max-width: 786px) {
  .dyp-main-hero {
    padding-top: 100px;

    .waves:first-child {
      top: -130px;
      right: -100px;
    }

    .dyp-coin-wrapper {
      min-height: 300px;
      .coin {
        right: 13%;
        bottom: 0%;
      }
      .background {
        left: 50%;
        width: 250px;
        margin-left: -126px;
      }
    }
  }

  .dyp-submenu-container{
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 820px) {
  .dyp-main-hero {
    .background {
      left: 20%;
      bottom: 8%;
    }
  }
}

@media screen and (max-width: 390px) {
  .dyp-main-hero {
    .dyp-coin-wrapper {
      // .background {
      //   left: 18%;
      // }
      .coin {
        right: 8%;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .dyp-main-hero {
    .dyp-coin-wrapper {
      // .background {
      //   left: 12%;
      // }
      .coin {
        right: 2%;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .dyp-main-hero {
    .dyp-coin-wrapper {
      // .background {
      //   left: 15%;
      // }
      .coin {
        top: 2%;
      }
    }
  }
}
