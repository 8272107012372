
.ourtokens-wrapper {
  // background: url(./tokenbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 56px 0px;
  padding: 3rem;
  min-height: 590px;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  background: linear-gradient(360deg, #554FD8 0%, #756EDF 98.32%);
border: 6px solid #EFF0F6;
border-radius: 56px 0px;

  @media only screen and (max-width: 991px) {
    padding: 1rem 6px 1rem 1rem;
    border-radius: 28px 0px;
    // min-height: 1230px;
  }
  @media screen and (max-width: 786px) {
    background-size: cover;
    min-height: auto;
    
  }

  .ourtoken-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #ffffff;
    display: grid;

    @media only screen and (max-width: 500px) {
      font-size: 40px;
      text-align: left;
    }
  }

  .ourtoken-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);

    @media only screen and (max-width: 500px) {
      text-align: left;
    }
  }

  .tokengraph {
    position: absolute;
    right: 0;
    padding: 0;
    top: -50px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);


    @media only screen and (max-width: 991px) {
      right: auto;
    top: auto;
    }
  }
}

.ourtoken-sphere {
  position: absolute;
  bottom: 0;
  left: -13%;
  z-index: -1;
}