.backgroundbuydyp {
    position: absolute;
    top: -7%;
    left: -13%;
    z-index: 1;
    width: 100%;
    animation: scale 3s ease-in-out infinite;
    -webkit-animation: scale 3s ease-in-out infinite;
}

@keyframes scale {
   0%{
    transform: scale(1);
   }
   50%{
    transform: scale(1.1);
   }
   100%{
    transform: scale(1);
   }
}

@-webkit-keyframes scale {
    0%{
     -webkit-transform: scale(1);
    }
    50%{
     -webkit-transform: scale(1.1);
    }
    100%{
     -webkit-transform: scale(1);
    }
 }

.buydyp-main-hero {
    min-height: 500px;
    background: linear-gradient(0deg, #EFF0F6 0%, rgba(239, 240, 246, 0) 73.43%);
    border-radius: 0px 0px 56px 56px;
    overflow: hidden;

    h1 {
        color: black;
    }
}

.buydypcoin{
    width: 250px;
    height: 250px;
}