.help-wrapper {
  margin-top: 60px;

  .contact-container{
    margin-top: 7rem;
  }

  .help-card {
    background: #ffffff;
    border: 4px solid #eff0f6;
    box-shadow: 0px 16px 32px rgba(118, 111, 223, 0.12);
    border-radius: 28px 0px;
    min-height: 250px;


    .form-button{
      margin-right: 15px;
    }

    .email-container {
      background: #eeeefc;
      border-radius: 12px 0px;
      cursor: pointer;
      transition: all 1s;
      -webkit-transition: all 1s;


      .form-email {
        font-size: 14px;
        font-weight: 500;
        color: #7670e0;
        display: none;
        transition: all 1s;
      -webkit-transition: all 1s;

      }
    }

    .email-container:hover {
      .form-email {
        display: block;
      }
    }

    h3 {
      color: #7670e0;
    }

    .phone-image {
      top: -85px;
      left: 5%;
      height: 175px;
      width: 320px;
      object-fit: contain;
    }



    .help-form {
      background: #ffffff;
      border: 2px solid #eff0f6;
      border-radius: 24px;
       
      @media screen and (max-width: 786px) {
        border: none !important;
      }
    }

    .custom-file-input {
      color: transparent;
    }

    input::file-selector-button  {
      visibility: hidden !important;
    }


    .custom-file-input::before {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      height: 35px;
      width: 100%;

      color: black;
      display: inline-block;
      outline: none;
      white-space: nowrap;
      user-select: none;
      -webkit-user-select: none;
      cursor: pointer;
      text-shadow: 1px 1px #fff;
      font-weight: 700;
      font-size: 0px !important;
    }
  }
}



.form-container-desc{
  width: 75%;
}

label{
  font-family: 'Poppins' sans-serif !important;
}

.help-ring {
  position: absolute;
  right: -10%;
  bottom: 0px;
}

@media screen and (max-width: 1200px) {
  .contact-container{
    gap: 100px;
  }
}

@media screen and (max-width: 786px) {
  .help-wrapper {
    .help-card {
      .phone-image {
        height: 167px;
        width: 210px;
        top: -85px;
        left: 23px;
      }
    }

    .contact-container {
      gap: 100px;
      margin-top: 5rem;
    }
  }

  .form-container-desc{
    width: 100%;
  }
}

@media screen and (max-width: 360px){
  .help-wrapper{
    .help-card{
      .form-button{
        margin-right: 0px;
      }
    }
  }
}


