.buy-dyp{
    min-height: 500px;
    margin-top: 50px;
    margin-bottom: 100px;

    .coin{
        position: absolute;
        right: -20%;
    }

    .coinBackground{
        top: 9%;
        position: absolute;
        width: 450px;
        right: -10%;
        animation: rotate 10s linear infinite;
        -webkit-animation: rotate 10s linear infinite;
    }

   

    .ring{
        position: absolute;
        right: -8%;
        top: -15%;
    }

    .sphere-1{
        position: absolute;
        top: -10%;
        height: 150px;
        left: 25%;
        width: 150px;
    }
    
    .sphere-2{
        position: absolute; 
        bottom: -30%;
        left: 45%;
   }
}



.buy-dyp::before{
    content: "";
    background-image: 
    url("https://cdn.worldofdypians.com/dypius/buy-dyp-bgshape.png"),
    linear-gradient(270deg, rgba(239, 240, 246, 0) 0%, #eff0f6 100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    border-radius: 56px 0 0 0;
}


@media screen and (max-width: 1399px) {
    .buy-dyp{
        .coin{
        right: -30%;
        }
        .coinBackground{
            right: -20%;
        }
    }
}

@media screen and (max-width: 912px) {
    .buy-dyp{
        min-height: 400px;
        .coinBackground{
            right: -15%;
            top: -15%;
        }
        .coin{
            right: -30%;
            top: -30%;
        }
    }
}

@media screen and (max-width: 576px) {

    .buy-dyp{
        min-height: 680px;
        margin-bottom: 50px;
        margin-top: 50px;
        .coinBackground{
            top: -45%;
            width: 300px;
            right: 4%;
        }

        .coin{
            width: 320px;
            right: 0%;
        }
        .ring{   
            right: -30%;
            top: 30%;
        }

        .sphere-1{
            left: 60%;
        }

        .sphere-2{
            bottom: 10%;
            left: -8%;
            height: 150px;
            width: 150px;
        }
    }

    .buy-dyp::before{
        background-position: center;
        background-size: cover;
    }
    
}

