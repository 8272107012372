.idypcoin {
    position: absolute;
    top: 8%;
    left: 15%;
    height: 200px;
    width: 200px;
}





.idypmainhero{
    min-height: 500px;
    background: linear-gradient(121.83deg, #554FD8 14.64%, #7770E0 85.55%);
    border-radius: 0 0 56px 56px;
}

.idypheroimg {
    position: absolute;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);

}

.idypring{
    width: 80px;
    height: 80px;
    position: absolute;
    top: -4%;
    left: 28%;
    filter: blur(2px) drop-shadow(0px 34px 34px rgba(31, 27, 103, 0.34));
}

.idyp-hero-images{
    min-height: 100px;
    .backgroundbuydyp{
        top: -30%;
    }
   
}


@media screen and (max-width: 1400px) {
    .idyp-hero-images{
        .backgroundbuydyp{
            top: 0%;
        }
        .idypcoin{
            left: -5%;
        }
       
    }
}
@media screen and (max-width: 1200px) {
    .idyp-hero-images{
        .backgroundbuydyp{
            top: 15%;
        }
        .idypcoin{
            left: -15%;
            top: 2%;
        }
       
    }
}


@media screen and (max-width: 786px) {

    .idyp-row-wrapper{
        position: relative;
        bottom: 25px;
    }

    .idypmainhero{
        padding-top: 25px;
        border-radius: 0 0 28px 28px;
        min-height: 550px;
    }
    .idypcoin{
        left: 0 !important;
    right: 0;
    top: 75px !important;
    bottom: 0;
    width: 175px;
    height: 175px;
    margin: auto;
    z-index: 3;
    }

    .backgroundbuydyp{
        left: 0 !important;
        right: 40px;
        top: 75px !important;
        bottom: 30px;
        width: 250px;
        height: 250px;
        margin: auto;
        z-index: 2;
    }
}