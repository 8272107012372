.presstable {
  padding: 20px;
  width: fit-content;
  margin: auto;
  background: linear-gradient(
    93.49deg,
    #aaa5eb 0%,
    #7770e0 22.98%,
    #645edc 39.94%,
    #7770e0 58.54%,
    #554fd8 83.7%,
    #aaa5eb 105.04%
  );

  img {
    width: 60px;
    height: 60px;
  }

  .pressbody {
    border: 1px solid #aaa5eb;
  }

  .presscell {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f1ff;
  }

  tr {
    display: flex;
    justify-content: space-between;
    gap: 1px;
  }
}

.radius1 {
  border-radius: 32px 0px;
}

.radius2 {
  border-radius: 0px;
}

.radius3 {
  border-radius: 0px 0px 32px 0px;
}

.radius4 {
  border-radius: 32px 0px 0px 0px;
}

.radius5 {
  border-radius: 32px 0px 32px 32px;
}

.radius6 {
  border-radius: 0px 32px 32px 0px;
}
.radius7 {
  border-radius: 32px 32px 0px 0px;
}

.radius8 {
  border-radius: 32px 0px 0px 32px;
}

.radius9 {
  border-radius: 0px 0px 32px 32px;
}

.radius10 {
  border-radius: 32px 32px 32px 0px;
}

.leftbg {
  background: #ffffff;
  filter: blur(14px);
  position: absolute;
  width: 95px;
  left: -45px;
  height: 100%;
  z-index: 3;
}

.rightbg {
  width: 95px;
  height: 100%;
  background: #ffffff;
  filter: blur(14px);
  position: absolute;
  right: -55px;
  top: 0px;
  z-index: 4;
}

@-webkit-keyframes moveSlideshow {
  100% {
    -webkit-transform: translateX(-100%);
    
  }
}

@keyframes moveSlideshow {
  100% {
    -webkit-transform: translateX(-100%);
  }
}


.pressbody  {
  height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  transform: translate3d(0, 0, 0);
  -webkit-animation: moveSlideshow 300s linear infinite;
  animation: moveSlideshow 300s linear infinite;
}