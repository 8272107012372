.main-hero {
  background: linear-gradient(121.83deg, #554fd8 14.64%, #7770e0 85.55%);
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  z-index: 2;
  position: relative;

  .heading {

    background-size: contain;
    background-repeat: no-repeat;
    min-height: 800px;

    .col-5 {
      gap: 15px;
    }

    .filled-orange-btn {
      width: 35%;
      height: 40px;
    }
  }
}

.sliderwrapper {
  height: 161px;
  background: url("https://cdn.worldofdypians.com/dypius/carouselbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: flex-start;

  .slick-list {
    margin: 0;
  }

  .rowwrapper {
    padding: 20px 16px;
  }
}

h1 {
  font-size: 40px;
  font-weight: 700;
}

.metaverse {
  background: linear-gradient(360deg, #554fd8 1.07%, #7770e0 88.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.digits {
  font-weight: 300;
  font-size: 26px;
  line-height: 33px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-right: 30px;

  span {
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  background: rgba(150, 100, 255, 1);
  opacity: 0.3;
  cursor: pointer;
}

.dot:nth-child(2) {
  height: 16px;
  width: 16px;
  opacity: 1;
}

@media only screen and (max-width: 900px) {
  .main-hero {
    padding-top: 50px;
    .heading {
      min-height: 1000px;
    }
  }

  h1 {
    font-size: 28px !important;
    margin: 0;
  }

  .list-card {
    img {
      width: 40px;
    }
  }

  .leftcol {
    .outline-btn {
      width: 100% !important;
    }
  }
}

.migrated-tokens-wrapper {
  border-radius: 12px;
  border: 2px solid #48c7c4;
}

.migrated-tokens {
  color: #f7f7fc;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.migrated-tokens-amount {
  color: #f7f7fc !important;
  text-align: right;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.migration-outer-progress {
  position: relative;
  border-radius: 30px;
  background: rgba(217, 229, 224, 0.3);
  height: 35px;
}

.migration-inner-progress {
  height: 100%;
  border-radius: 30px;
  border: 0.5px solid #48C7C4;
  background: linear-gradient(90deg, #4ED5D2 0%, #48C7C4 100%);
  z-index: 2;
}
.progress-dots {
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.migration-dot {
  height: 8px;
  width: 8px;
  background: rgba(72, 199, 196, 0.4);
  border-radius: 50%;
}

.migration-percentage {
  color: #fff !important;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.migration-dash {
  height: 16px;
  width: 4px;
  background: #fff;
  border-radius: 10px;
}

.migration-progress-info {
  color: #f7f7fc !important;
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}
