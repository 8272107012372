#security {
  background-image: url("https://cdn.worldofdypians.com/dypius/securitybg.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.securitybg {
  background: url(https://cdn.worldofdypians.com/dypius/securitybg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.security-item {
  background: linear-gradient(180deg, #eff0f6 0%, #eff0f6 100%);
  border-radius: 28px 0px;
  padding: 10px 24px;
  width: 32%;
  min-height: 80px;
  display: grid;
  align-items: center;

  @media only screen and (max-width: 853px) {
    width: 100% !important;
  }

  span,
  a {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 27px;
    text-decoration: none !important;
    color: #14142b;
  }
}

.security-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(30, 32, 41, 0.6);
}

.sup-asset-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.018em;
  color: #7670e0;
  margin: 0;
}

.auditedby-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #6059da;
  // visibility: hidden;
}

.audited-item {
  background: #ffffff;
  border: 1px solid #eff0f6;
  padding-top: 20px;
  padding-bottom: 20px;
}

.viewsite {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #7670e0;
}

.auditedwrapper {
  border: 2px solid #eff0f6;
  box-shadow: 0px 12px 44px rgba(17, 17, 17, 0.08);
  border-radius: 18px 0px;
  position: relative;
  background: white;

  &::after {
    content: "Audited by";
    position: absolute;
    width: 91px;
    height: 33px;
    top: -17px;
    right: -2px;
    background: #eff0f6;
    border: 2px solid #eff0f6;
    border-radius: 12px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #6059da;
  }
}

.audited-item:nth-child(1) {
  border-left: none;
  border-right: none;
  border-top: none;
}

.audited-item:nth-child(2) {
  border-left: none;
  border-right: none;
  border-top: none;
}

.audited-item:nth-child(3) {
  border: none;
}

.repowrapper {
  background: linear-gradient(91.49deg, #aaa5eb 0%, #7770e0 100%);
  border: 2px solid #eff0f6;
  border-radius: 18px 0px;
  padding: 16px;
  position: absolute;
  bottom: -60px;

  p,
  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #f7f7fc;
    margin: 0;
  }
}

.github-logo {
  padding: 10px;
  background: white;
  border-radius: 10px;
  border: 2px solid #7770e0;
}

.github-btn {
  background: #ffffff;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: center;
  border: 2px solid #7770e0;
  transition: all 0.5s;

  span {
    color: #554fd8;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.github-btn:hover {
  color: #554fd8 !important;
  border-color: #3e3aa5 !important;
  box-shadow: 0px 8px 18px rgba(85, 79, 216, 0.4);
}
