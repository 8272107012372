.dyp-token {
  position: relative;
  min-height: 600px;
  width: 101%;
  margin-top: 7rem;

  .blue-half {
    position: relative;
    width: 100%;
    min-height: 600px;
    background: linear-gradient(121.83deg, #7770E0 14.64%, #554FD8 85.55%);
    border-radius: 56px 0;

    .ring-1{
      position: absolute;
      top: 18%;
      right: -43%;
      width: 70px;
      height: 70px;
      filter: blur(2px) drop-shadow(0px 34px 34px rgba(85, 79, 216, 0.24));
    }

 

    .ring-2{
      position: absolute;
      bottom: 3%;
      right: 0%;
      width: 100px;
      height: 100px;
      filter: blur(2px) drop-shadow(0px 34px 34px rgba(85, 79, 216, 0.24));

    }

    .coin {
      position: absolute;
      top: 10%;
      left: 20%;
      height: 500px;
      width: 500px;
    }

    .coin:first-child {
      z-index: 2;
      -webkit-animation: scale-up-center 1s linear infinite alternate both;
              animation: scale-up-center 1s linear infinite alternate both;
    }

    .coin:first-child{
      animation-name: scale-up-center;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;

      -webkit-animation-name: scale-up-center;
      -webkit-animation-duration: 1.5s;
      -webkit-animation-iteration-count: infinite;
    }

    @-webkit-keyframes scale-up-center {
      0% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
      }
      100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
      }
    }
    @keyframes scale-up-center {
      0% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
      }
      100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
      }
    }
  }

  .token-info {
    gap: 20px;
    margin-right: 70px;


    .ring{
      position: absolute;
      right: -17%;
      top: 42%;
      filter: blur(2px) drop-shadow(0px 34px 34px rgba(85, 79, 216, 0.24));
      animation: bounce 5s ease-in-out infinite;
      -webkit-animation: bounce 5s ease-in-out infinite;


    }
    @keyframes bounce {
      0%{
        transform: translateX(0px) translateY(0px);
      }
      50%{
        transform: translateX(-10px) translateY(-10px);
      }
      100%{
        transform: translateX(0px) translateY(0px);
      }
    }

    @-webkit-keyframes bounce {
      0%{
        -webkit-transform: translateX(0px) translateY(0px);
      }
      50%{
        -webkit-transform: translateX(-10px) translateY(-10px);
      }
      100%{
        -webkit-transform: translateX(0px) translateY(0px);
      }
    }


    .coin-card {
      width: 270px;
      border-radius: 12px;
      border: 2px solid rgb(214, 216, 231);

      .card-line {
        background: #7770E0;
        height: 40px;
        width: 5px;
        position: absolute;
        left: -1px;
        border-radius: 0 5px 5px 0;
      }
    }

  }
}

.dyp-token::before {
  content: "";
  background: #eff0f6;
  background-image:
   url("https://cdn.worldofdypians.com/dypius/dypTokenBackground.png"),
    linear-gradient(270deg, #eff0f6 0%, #fff 49.11%);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 56px 0 56px 0;
}



@media screen and (max-width: 912px) {
  .dyp-token{
    .blue-half{
      .coin{
        left: 40%;
      }
    }
  }
}

@media screen and (max-width: 576px) {

.dyp-token{
  min-height: 400px;
  margin-top: 3rem;

  .blue-half{
    min-height: 400px;

    .ring-1{
      right: -53%;
      top: 10%;
    }

    .coin{
      width: 300px;
      height: 300px;
      left: 20%;
    }
  }

  .token-info{
    padding-top: 25px;

    .ring{
      right: -12%;
    }
  }

  gap: 20px;
}
.dyp-token::before{
  background-position: right;
}


 
}


@media screen and (max-width: 360px) {
  .infinite-cards-container{
    padding: 0px 0px !important;
  }
  
}
