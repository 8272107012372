.header-wrapper {
  background: #f7f7f7;

  .nav-link {
    color: #111827 !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
  }
}

#hamburgermenu {
  width: 45px;
  height: 45px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  cursor: pointer;
  border-radius: 8px;
  right: 12px;
    top: 12px;
    background: white;
    z-index: 23;

}

 #hamburgermenu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 55%;
  background: #534DD2;
  border-radius: 20px;
  opacity: 1;
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#hamburgermenu span:nth-child(1) {
  top: 13px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburgermenu span:nth-child(2) {
  top: 21px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburgermenu span:nth-child(3) {
  top: 29px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburgermenu.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 13px;
  left: 13px;
}

#hamburgermenu.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#hamburgermenu.open {
  border: 1px solid #F7F7FC;
  background: inherit;

  span{
    background: #F7F7FC;
  }
}

#hamburgermenu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 30px;
  left:13px;
}

.menu {
  transition: all .3s,background 0s;
  -webkit-transition: all .3s,background 0s;

  position: fixed;
  right: 0;
  top: 0;
  z-index: 22;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  transform: translateY(-700px);
  -webkit-transform: translateY(-700px);

  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;

  ul li {
    list-style-type: none;
    margin: 8px;
  }

  
 .mobile-navlink:active{
  background: #4843AE;
 }

 .tools-border{
  position: absolute;
  top: 5px;
  left: -30px;
  z-index: -1;
 }

 .dyp-tools-btn{
  background: linear-gradient(280.88deg, #F1643E 9.02%, #F78057 90.66%);
box-shadow: 0px 16px 32px rgba(241, 101, 63, 0.6);
border-radius: 12px 0px;
z-index: 55;
transition: all .3s;
-webkit-transition: all .3s;

 }

 .dyp-tools-btn:active{
  transform: translateX(10px) translateY(10px);
  -webkit-transform: translateX(10px) translateY(10px);

 }

  ul li a{
    font-weight: 500;
    color: #FFFFFF !important;
  }

  .mobile-hr{
    background: white;
    margin: 0;
    height: 2px;
  }


}


#bgmenu{
  background: linear-gradient(304.92deg, #554FD8 24.56%, #7770E0 79.94%);
border-radius: 0px 0px 14px 0px;
}


.openMenu{
  transform: translateY(70px);
  -webkit-transform: translateY(70px);
  box-shadow:0 0 0 1000px rgba(0,0,0,.50);
}

.openMenu-withFlyout {
  transform: translateY(105px);
  -webkit-transform: translateY(105px);
  box-shadow:0 0 0 1000px rgba(0,0,0,.50);
}


.mobile-navbar{
  background: linear-gradient(304.92deg, #554FD8 24.56%, #7770E0 79.94%);
box-shadow: 0px 4px 12px rgba(82, 77, 209, 0.32);
border-radius: 0px 0px 14px 0px;
position: fixed;
z-index: 30;
transform: translateY(0px);
-webkit-transform: translateY(0px);

transition: all .25s ease-in;
-webkit-transition: all .25s ease-in;

}

.white-navbar{
  background: linear-gradient(304.92deg, #EFF0F6 24.56%, #FFFFFF 79.94%) !important;
}

.white-burger{
  background: #554FD8 !important;
  span{
     background: #FFFFFF !important;
  }
}

.hide-nav{
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);

}
