.support-main-wrapper {
  min-height: 500px;
  background: linear-gradient(0deg, #eff0f6 0%, #ffffff 73.43%);
  border-radius: 0px 0px 56px 56px;

  

  .image-container {
    .brain {
      position: absolute;
      z-index: 2;
      top: -175px;
      right: -50px;
    }
    .shape {
      position: absolute;
      z-index: 4;
      top: 35px;
    }
    .phone {
      position: absolute;
      z-index: 3;
      top: -135px;
    }

    .bluehero {
      position: absolute;
      filter: blur(20px);
      z-index: 1;
      top: -210px;
      left: 9px;
    }
  }

  .support-sphere{
    position: absolute;
    width: 150px;
    bottom: -120%;
  }
}

.submenu-link{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  font-size: 15px;
  gap: 9px;
  color: rgba(85, 79, 216, 1);
    
  @media screen and (max-width: 786px) {
    justify-content: space-between;
  }
    
      .submenu-indicator{
        animation: pulse 1.5s linear infinite;
        -webkit-animation: pulse 1.5s linear infinite;
      }
      
      
  
}


@keyframes pulse{
  0%{
    transform: scale(1);
  }

  50%{
    transform: scale(1.4);
  }

  100%{
    transform: scale(1);
  }
}

@-webkit-keyframes pulse{
  0%{
    -webkit-transform: scale(1);
  }

  50%{
    -webkit-transform: scale(1.4);
  }

  100%{
    -webkit-transform: scale(1);
  }
}




@media screen and (max-width: 576px) {
  .shape {
    width: 100px;
    top: 200px !important;
    left: 0;
  }
  .phone {
    top: 100px !important;
    width: 250px;
    left: 0;
  }
  .brain {
    top: 40px !important;
    right: -65px !important;
    width: 250px;
    left: 75px;
  }
  .bluehero {
    top: -15px !important;
    left: 10px !important;
    width: 150px;
    left: 0;
  }
  .support-submenu-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .submenu-link{
      width: 100px;
      font-size: 12px;
      padding: 0;
      justify-content: center;
    }
  }
  
  .support-banner{
    width: 400px;
    position: relative;
    right: 30px;
  }
}
