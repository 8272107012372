.gov-card {
  background: #ffffff;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 30px 0px 18px;
  padding: 24px;
  height: fit-content;
  width: 31%;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  .green-tag {
    height: 20px;
    width: 20px;
    background: #75cac2;
    border-radius: 12px 0px;
    position: absolute;
    top: 10%;
    left: 16%;

    @media only screen and (max-width: 991px) {
      left: 7%;
    }
  }

  .tag {
    width: 50px;
    height: 50px;
    background: #7770e0;
    box-shadow: 0px 10px 12px rgba(119, 112, 224, 0.24);
    border-radius: 18px 0px;
    position: relative;
    top: 0%;
    left: 0%;
  }

  .govnr {
    color: #aaa5eb;
    font-size: 80px;
    font-weight: 600;

    @media only screen and (max-width: 991px) {
      font-size: 69px !important;
    }
  }

  .connectwallet-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.018em;
    color: #1e2029;
  }
}

.one {
  margin-top: 8rem;
  @media only screen and (max-width: 991px) {
    margin-top: auto;
  }
}

.three {
  position: relative;
  right: 0;
  top: -167px;

  @media only screen and (max-width: 991px) {
    top: auto;
  }
}

.descwrapper {
  max-width: 275px;

  @media only screen and (max-width: 991px) {
    max-width: fit-content;
  }
}
