.disclaimer-content {
    background: #FFFFFF;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 18px 0px;  
    position: relative;
    top: 147px;
    margin-bottom: 20rem;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0075em;
        color: #1E2029;  
    }

}

