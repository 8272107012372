.vaultWrapper {
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 18px 0px;
  min-height: 153px;
  padding: 20px;
  position: relative;
  width: 374px;
  z-index: 2;





  @media only screen and (max-width: 891px) {
    width: 100% !important;
  }

  .purplediv {
    width: 4px;
    height: 45px;
    position: absolute;
    background: #7770e0;
    border-radius: 0px 100px 100px 0px;
    left: -1px;
  }

  .poolTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #4e4b66;
  }

  .aprTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.018em;
    color: #7770e0;
    align-items: baseline;

    @media only screen and (max-width: 500px) {
      font-size: 20px;

    }
  }

  .aprText {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .locktime-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(143, 144, 148, 0.6);
  }

  .lock-title {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.018em;
    color: #6e7191;
  }
}

.gray-box-large{
  width: 118px;
border-radius: 12px;
height: 34px;

background: #EFF0F6;
}

.gray-box-small{
  width: 61px;
height: 34px;
border-radius: 12px;
background: #EFF0F6;
}

.top-tick {
  border: 2px solid #F0603A;
  position: relative;
  &::after {
      content: "Top pick";
      position: absolute;
      width: 91px;
      height: 33px;
      top: -17px;
      right: -2px;
      background-color: #F0603A;
      background-image: url(https://cdn.worldofdypians.com/dypius/topTick-icon.svg);
      background-repeat: no-repeat;
      border-radius: 6px 0px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      text-align: right;
     
      background-position: 9px 3px;
      padding: 5px 10px 5px 0;
  }

  .purplediv {
    background: #F0603A !important;
  }
}

.eth {
  &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 33px;
      top: -17px;
      right: -2px;
      background-image: url(https://cdn.worldofdypians.com/dypius/ethbgSupport.svg);
      background-repeat: no-repeat;
      border-radius: 6px 0px;
      padding: 5px 10px 5px 0;
  }
}

.avax {
  &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 33px;
      top: -17px;
      right: -2px;
      background-image: url(https://cdn.worldofdypians.com/dypius/avaxbgSupport.svg);
      background-repeat: no-repeat;
      border-radius: 6px 0px;
      padding: 5px 10px 5px 0;
  }
}


.bnb {
  &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 33px;
      top: -17px;
      right: -2px;
      background-image: url(https://cdn.worldofdypians.com/dypius/bscbgSupport.svg);
      background-repeat: no-repeat;
      border-radius: 6px 0px;
      padding: 5px 10px 5px 0;
  }
}

.list-coin{
  width: 40px;
  height: 40px;
  transition: all .25s;
}

.list-coin:hover{
  z-index: 7;
  scale: 1.1;
}

.list-coin:nth-child(2){
  transform: translateX(-10px);
}
.list-coin:nth-child(3){
  transform: translateX(-20px);
}
.list-coin:nth-child(4){
  transform: translateX(-30px);
}
.list-coin:nth-child(5){
  transform: translateX(-40px);
}
.list-coin:nth-child(6){
  transform: translateX(-50px);
}
.list-coin:nth-child(7){
  transform: translateX(-60px);
}
