.our-team{
    min-height: 700px;
    position: relative;
    background-image: url('https://cdn.worldofdypians.com/dypius/ourTeamBg.png');
    background-size: 100%;
    background-repeat: no-repeat;

    .our-team-background{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 700px;
        border-radius: 56px 0px;
        z-index: -1;
    }

    .team-container{

        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        .team-card{
            width: 315px;
            height: 420px;
            background: linear-gradient(180deg, #7770E0 0%, #554FD8 100%);
            box-shadow: 0px 32px 64px rgb(20 20 43 / 8%);
            border-radius: 28px 0px;
            position: relative;
            z-index: 1;

            .profile-pic{
                border-radius: 28px 0;
                width: 270px;
                height: 280px;
                object-fit: cover;
            }

           .title-container{

            .accordion-toggler{
                transition: all .25s;
                -webkit-transition: all .25s;

            }

            .rotate{
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
            }
           }
           
        }
        
        .team-card:nth-child(3n-2){
            
            margin-top: 70px;

        }

        .team-card:nth-child(3n-1){
            margin-top: 35px;
        }

        .team-info{
            width: 310px;
            background: linear-gradient(180deg, #FCFCFC 0%, #F7F7FC 100%);
            box-shadow: 0px 32px 64px rgba(20, 20, 43, 0.08);
            border-radius: 28px 0px;
            padding-top: 40px;
            

            p{
                font-size: 12px;
            }
        }
    }
    

}

.our-team::before{
    content: "";

    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
}


@media screen and (max-width: 786px) {


    .our-team{

        .team-container{
            grid-template-columns: auto;
            
            .team-card{
                width: 350px;
               
            }
         
        }
        
    }
    
}


@media screen and (max-width: 1399px) {


    .our-team{

        .team-container{
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
}

@media screen and (max-width: 920px) {


    .our-team{

        .team-container{
            grid-template-columns: repeat(2, 1fr);
        }
    }
    
}

@media screen and (max-width: 600px) {
    .profile-pic{
        width: 300px !important;
    }

    .title-container{
        width: 300px !important;
    }

    .our-team{

        .team-container{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    
}

// @media screen and (max-width: 970px) {


//     .team-card{ 
//         width: 290px !important
//     }

// }
