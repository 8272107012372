.updates-wrapper{
    position: relative;
    min-height: fit-content;
    background-image: url('https://cdn.worldofdypians.com/dypius/updatesBackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.updates-container{
    min-height: 600px;
    background: linear-gradient(0deg, #EFF0F6 0%, #FFFFFF 73.43%);
    border-radius: 0px 0px 56px 56px;


    .years{
        background: #FFFFFF;
        border: 1px solid #A5A2E7;
        border-radius: 8px;
        width: 200px;
        height: 50px;
        padding: 0 12px;
      }

    .years-wrapper{
        background: #EFF0F6;
        border-radius: 8px;
        padding: 5px;
        p{
            color: #6E7191 !important;
            margin-bottom: 0;
            cursor: pointer;
            font-size: 12px;
            padding: 5px 15px;
        }

        .selected-year{
            background: #5953D9;
            border-radius: 8px;
            color: white !important;
        }
    }
    .update-card-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .update-card{
            background: #FFFFFF;
            box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
            border-radius: 12px 0px;
            width: fit-content;
            .date{
                background: linear-gradient(180deg, #FAC8B7 0%, #F8845B 63.29%);
                border-radius: 8px;
                width: 60px;
                padding: 10px;
                height: 100px;
                .month{
                    color: #F8845B;
                    padding: 4px 8px;
                    background: #FFFFFF;
                    border-radius: 4px;
                }
            }
            .update-image{
                background: #D9D9D9;
                border-radius: 6px;
                width: 170px;
                height: 100px;
                object-fit: cover;
            }
        }
    }

    .updates-bluehero{
        filter: blur(60px);
        position: absolute;
        right: -10%;
        z-index: -1;
    }
    .updates-rainbowHero{

        position: absolute;
        left: -25%;
        z-index: -1;
        bottom: -15%;
    
    }

    .updates-ring{
        position: absolute;
        right: -5%;
        bottom: -12%;
    }
}

@media screen and (max-width: 1200px) {
   .updates-container{
    .update-card-container{
        grid-template-columns: repeat(2, 1fr);
    }
   }
}


@media screen and (max-width: 786px) {

    .updates-container{
        .years-wrapper{
            padding: 10px;
        }
    }

    .update-card-container{
        grid-template-columns: auto !important;
        .update-card{
            width: 275px !important;
        }
    }

    .updates-ring{
        bottom: -13% !important;
        z-index: -1;
    }
}
