.viewtutorials {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #6962dc;
}

.buydypcard-wrapper {
  background: #ffffff;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px 0px;
  // max-width: 313px;
  border: 2px solid #ffffff;
  width: 100%;
  padding: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: all .25s ;
  -webkit-transition: all .25s ;




  .buydypcard-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  .buydypcard-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;
    color: #14142b;
  }

  .videodesc-wrapper {
    border-radius: 100px;
    background: rgba(85, 79, 216, 0.3);
    padding: 2px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }

  .videodesc {
    color: #554FD8;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rightarrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #7770e0 0%, #554fd8 100%);
    border-radius: 10px;
    padding: 7px 12px;
  }

  .walletimg {
    width: 20px;
    height: 20px;
  }

  .vidthumbnail {
    border-radius: 6px;
    width: 100px;
  }

  .videotitle {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.018em;
    color: #14142b;
    width: 124px;
  }

  .buyitemcard {
    background: #f5f6fa;
    border: 2px solid #f5f6fa;
    border-radius: 6px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all .25s;
    -webkit-transition: all .25s;
    flex-wrap: inherit;

    .buyitem-arrow{
      position: relative;
      right: 5px;
    }
  }

  .buyitemcard:last-child{
    margin-bottom: 0 !important;
  }

  .buyitemcard:hover{
    border: 2px solid rgba(201, 198, 243, 1);
  }
}


.buydypcard-wrapper:hover{
  border: 2px solid rgba(201, 198, 243, 1);
}

.walletname {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;

  color: #4e4b66;
}
