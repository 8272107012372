@import "../Screens/Home/home";
@import "../Screens//Home/MainHero/mainhero";
@import "../Screens/Home/DypMetaverse/dypmetaverse";
@import "../Screens/Home/DypTools/dyptools";
@import "../Screens/Home/ChainlinkData/chainlinkdata";
@import "../Screens/Dyp/EducationalTools/educationaltools";
@import "../Screens/Dyp/Vault/vault";
@import "../Screens/Dyp/SupportedAssets/supportedassets";
@import "../Screens/Dyp/OurTokens/ourtokens";
@import "../Screens/Dyp/Tokenomics/tokenomics";
@import "../Screens//About/Brand/brand";
@import "../Screens/About/Security/security";
@import '../Screens//Disclaimer/disclaimer';
@import '../Screens/Disclaimer/MainHero/disclaimerhero';
@import '../Screens/Disclaimer/DisclaimerContent/disclaimercontent';
@import '../Screens/Governance/governance';
@import '../Screens/BuyDyp/BuyDypHero/buydyphero';
@import '../Screens/BuyDyp/buydypscreen';
@import '../Screens/IDyp/IDypHero/idyphero';
@import '../Screens/IDyp/BuyIDyp/buyidyp';
@import '../Screens/BuyDyp/SupportedWallets/supportedwallet';

@import "../components/Header/header";
@import "../components/AuditedByItem/auditedByItem";
@import "../components/DypSystem/dypSystem";
@import "../components/CometBg/_cometbg";
@import "../components/TokenCards/tokencards";
@import "../components/ProductCards/productcards";
@import "../components/VaultCard/vaultcard";
@import "../components/ScrollTop/scrollTop";
@import "../components/GovCards/govcards";
@import '../components/BuyDypCard/buydypcards.scss';
// @import "../Screens/Caws/colors";
// @import "../Screens/Caws/responsive";
// @import "../Screens/Caws/caws";
// @import "../Screens/Caws/nftWhiteList";
// @import "../Screens/Caws/components/nftStaking";
// @import "../Screens/Caws/components/Nft/AdoptACat/adoptACat";
// @import "../Screens/Caws/components/Nft/CatsAndWatchesSociety/catsAndWatchesSociety";
// @import "../Screens/Caws/components/Nft/CatsAndWatchesSocietyBenefits/catsAndWatchesSocietyBenefits";
// @import "../Screens/Caws/components/Nft/CatSocietyRanking/catSocietyRanking";
// @import "../Screens/Caws/components/Nft/CatsTimeLine/catsTimeLine";
// @import "../Screens/Caws/components/Nft/CatWhiteList/catWhiteList";
// @import "../Screens/Caws/components/Nft/CawsRoadmap/cawsRoadmap";
// @import "../Screens/Caws/components/Nft/CawsTraits/cawsTraits";
// @import "../Screens/Caws/components/General/AssetsCard/assetsCard";
// @import "../Screens/Caws/components/General/Button/button";
// @import "../Screens/Caws/components/General/AuditedByItem/auditedByItem";
// @import "../Screens/Caws/components/General/Header/header";
// @import "../Screens/Caws/components/General/PillButton/pillButton";
// @import "../Screens/Caws/components/General/PillsSlider/pillsSlider";
// @import "../Screens/Caws/components/General/SingleAsset/singleAsset";
// @import "../Screens/Caws/components/General/TitleWithParagraph/titleWithParagraph";
// @import "../Screens/Caws/CawsGame/cawsgame";


// @import "../Screens/Caws/components/Nft/FullScreenMainHero/fullScreenMainHero";



* {
  font-family: "Poppins", sans-serif !important;
}

.MuiFormHelperText-root{
  color: #d32f2f !important;
}

.filled-btn {
  color: #f7f7fc;
  background: linear-gradient(to left, #7770E0, #3E3AA5, #554FD8, #7770E0);
  border-radius: 10px;
  background-size: 300%;
  background-position: 0 0;
  height: 40px;
  padding: 0 24px;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  font-size: 14px;
  letter-spacing: 0.75px;
  font-weight: 500
}

.filled-btn:hover {
  color: white !important;
  background-position: 100% 0 !important;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.09) !important;
}



.filled-orange-btn {
  background: linear-gradient(276.34deg, #E04C24 0%, #F8845B 100%);
  background: -webkit-linear-gradient(276.34deg, #E04C24 0%, #F8845B 100%);

box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.09);
border-radius: 10px;
color: white !important;
background-size: 300%;
padding: 0 20px;
height: 40px;

}

.filled-orange-btn:hover {
  background: linear-gradient(276.34deg, #E04C24 0%, #F8845B 100%) !important;
  background: -webkit-linear-gradient(276.34deg, #E04C24 0%, #F8845B 100%) !important;

}

.filled-orange-btn:focus {
  background: linear-gradient(276.34deg, #F8845B 0%, #F8845B 100%) !important;
  background: -webkit-linear-gradient(276.34deg, #F8845B 0%, #F8845B 100%) !important;

  border: 3px solid #FFDBCF !important;
  outline: #FFDBCF !important;
}

.outline-btn {
  color: #554fd8;
  height: 40px;
  background-color: #fff;
  border: 2px solid #554fd8;
  font-weight: 500;
  border-radius: 10px !important;
  font-size: 14px;
  letter-spacing: 0.75px;
  padding: 0 24px;
  // display: flex !important;
  // align-items: center;
  transition: all .5s;

}

.outline-btn:hover {
  color: #554fd8 !important;
  border-color: #3e3aa5 !important;
  box-shadow: 0px 8px 18px rgba(85, 79, 216, 0.4);
}

.outline-btn:focus {
  border-color: #554fd8 !important;
}


.tymodal {
  border: 6px solid #AAA5EB;
box-shadow: 0px 60px 120px #14142a1f;
border-radius: 56px 0px;
}

.modal-header {
  border-bottom: none !important;
}


.hidescroll{
  overflow: hidden;
  pointer-events: none;
}

::file-selector-button{
  visibility: hidden;
}


@media screen and (max-width: 820px) {
  html{
    overflow-x: hidden !important;
  }
  body{
    overflow-x: hidden !important;
  }
}
