.rings {
  position: absolute;
  top: -120px;
  left: 0;
  z-index: -1;
}

.bluehero-solutions {
  filter: blur(79.8781px);
  position: absolute;
  bottom: -160px;
  z-index: -1;
  right: -50px;
  width: 500px;
}

.dots {
  left: 0%;
  position: absolute;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);

  bottom: -16%;
  z-index: -1;
}

.mobile-popup-wrapper {
  background: linear-gradient(0deg, #eff0f6 0%, rgba(239, 240, 246, 0) 73.43%);
  border-radius: 10px;
}

.solutions-card-container {
  grid-template-columns: auto auto auto auto;
  gap: 40px;

  .solutions-card {
    background: linear-gradient(
      180deg,
      rgba(239, 240, 246, 0) 0%,
      #eff0f6 100%
    );
    border-radius: 28px 0px;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;

    .clipboard {
      background: #ffffff;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 10px;

      img {
        height: 30px;
        width: 30px;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .solutions-card:hover {
    border: 2px solid rgba(170, 165, 235, 1);
    color: #5650d8;
  }
}

@media screen and (max-width: 1200px) {
  .solutions-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 820px) {
  .solutions-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 786px) {
  .solutions-card-container {
    grid-template-columns: auto;
  }

  .mobile-popup-wrapper {
    height: 300px;
    overflow: auto;
  }
}

.mobile-content-list {
    padding: 10px 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;
  }
