.supportedAssets-wrapper {
  background: linear-gradient(180deg, rgba(239, 240, 246, 0) 0%, #eff0f6 100%);
  border-radius: 56px 0px;


.assets-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);

  .supported-assets-card{
    width: auto !important;
  }
}

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6E7191;
  }

  .select-chain {
    color: #554FD8;
    background: white;
    position: absolute;
    top: -11px;
    left: 10px;
    font-size: 10px;
    padding: 0 3px;
  }

  .chain-wrapper {
    // position: relative;
    // left: 0;
    display: grid;
    // flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    width: fit-content;
    padding: 0;

    img {
      cursor: pointer;
    }

   
  }

  .types-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    padding: 0 4rem;
  }

  .type-wrapper{
    background: #EFF0F6;
    border-radius: 8px;
    padding: 8px ;

    p{
      margin-bottom: 0;
      font-size: 15px;
      padding: 5px 16px;
      cursor: pointer;
    }

    p.active{
      color: #FFFFFF;
      background: #5953D9;
      border-radius: 8px;
    }

  }

  .actiontype {
    cursor: pointer;
    position: relative;
    padding: 10px;
    padding-bottom: 15px;
    min-width: 80px;
    span {
      color: #6e7191;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: center;
    }
  } 
    //  .active {
    //   background: #ffffff;
    //   border: 1px solid #554fd8;
    //   border-radius: 12px;
    //   padding: 10px;
    //   padding-bottom: 15px;
    //   min-width: 80px;
    //   span {
    //     color: #554fd8;
    //   }
    // } 
    
    .activetype-dot {
      position: absolute;
      bottom: 6px;
      background: #554fd8;
      box-shadow: 0px 3px 4px rgba(227, 6, 19, 0.26);
      border-radius: 50%;
      height: 8px;
      width: 8px;
      left: 46%;
    }

    .coins{
      background: #FFFFFF;
      border: 1px solid #A5A2E7;
      border-radius: 8px;
      width: 200px;
      height: 50px;
      padding: 0 12px;
    }

    .coin-menu{
      width: 200px;
      height: 180px;
      background: #FFFFFF;
      box-shadow: 0px 12px 14px rgba(85, 79, 216, 0.12);
      border-radius: 8px;

      .coin{
        cursor: pointer;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        padding-left: 5px;
      }
      
      .coin:nth-child(3) {
        border-bottom: none !important;
      }

      .coin:hover{
        background: rgba(229, 227, 255, 0.4);
      }
    }
}

.pool-coins:first-child {
  transform: translateX(0px);
  /* z-index: 6; */
}

.pool-coins:nth-child(2) {
  transform: translateX(-10px);
  /* z-index: 5; */
}


.spiral {
  position: absolute;
  right: -5%;
  bottom: -22%;
  width: 200px;
  height: 200px;
  z-index: 1;
}


hr{
  height: 3px;
  width: 100%;
  background: linear-gradient(90deg, rgba(85, 79, 216, 0) 0%, #554FD8 53.13%, rgba(85, 79, 216, 0) 101%);
}

.viewmorebtn {
   right: 44%; 

   @media only screen and (max-width: 888px) {
    right: auto;
    left: 50%;
    margin-left: -90px;
    
   }
}


@media screen and (max-width: 880px) {
  .assets-container{
    // grid-template-columns: auto !important;
    display: flex !important;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 786px) {

  

  .supportedAssets-wrapper{

    .chain-wrapper{
      width: 100%;
      grid-template-columns: auto auto auto;
      justify-content: space-around;
    }

    .types-wrapper{
      justify-content: space-around;
      flex-direction: column;
    }
  }

  .supported-description{
    text-align: center;
  }
}  

.spiral2 {
  position: absolute;
    bottom: -40px;
    height: 156px;
    width: 156px;
    right: -5px;
    z-index: -1;

}
