.vaultwrapper {
  .vaultInner {
    width: 374px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(30, 32, 41, 0.6);
    }
  }
}

#vault {
  background: url(https://cdn.worldofdypians.com/dypius/vaultbg.png) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  position: relative;
}

@media screen and (max-width: 786px) {
  #vault{
    padding: 50px 0;
  }
}