

.video-wrapper{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
grid-template-rows: repeat(4, 1fr);
grid-column-gap: 25px;
grid-row-gap: 25px;




.card-1 {
   grid-area: 3 / 1 / 5 / 3; 
   position: relative;
   top: 30px;
  }
.card-2 {
   grid-area: 3 / 3 / 5 / 5; 
   position: relative;
   top: 20px;
  }
.card-3 {
   grid-area: 3 / 5 / 5 / 7; 
   position: relative;
   top: 10px;
  }
.card-4 {
   grid-area: 1 / 2 / 3 / 4; 
   position: relative;
   top: 20px;
  }
.card-5 {
   grid-area: 1 / 4 / 3 / 6;
   position: relative;
   top: 10px; 
  }
  
    @media screen and (max-width: 786px) {
      grid-template-columns: auto;
    }
    
    .cardWrapper {
      border-radius: 12px 0px;
      position: relative;
      width: fit-content;
      box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      transition: all .5s;
      -webkit-transition: all .5s;

    }

    .cardWrapper:hover{
      transform: translateX(5px) translateY(-5px);
      -webkit-transform: translateX(5px) translateY(-5px);

    }
  
    
  .sphere:first-child{
    height: 150px;
    width: 150px;
        top: 38%;
    left: 16%;
  }

  .sphere:nth-child(2){
    height: 200px;
    width: 200px;
    right: 10%;
    top: 5%;
  }

  .sphere:nth-child(3){
    height: 125px;
    width: 125px;
    bottom: -13%;
    left: 60%
  }
  
  
    .eduImg {
      border-radius: 12px 0px;
      height: 120px;
      object-fit: cover;
  
      @media only screen and (max-width: 991px) {
          height: 140px !important; 
      }
    }
  
    .purpleArrow {
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }



  
 

  @media screen and (max-width: 912px) {
   .video-wrapper{
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: auto;
    .card-1{
      grid-area: auto;
      top: 0;
    }
    .card-2{
      grid-area: auto;
      top: 0;
    }
    .card-3{
      grid-area: auto;
      top: 0;
    }
    .card-4{
      grid-area: auto;
      top: 0;
    }
    .card-5{
      grid-area: auto;
      top: 0;
    }
   }
  }
  @media screen and (max-width: 576px) {
   .video-wrapper{
    grid-template-columns: auto;
    grid-template-rows: auto;
    .card-1{
      grid-area: auto;
      top: 0;
    }
    .card-2{
      grid-area: auto;
      top: 0;
    }
    .card-3{
      grid-area: auto;
      top: 0;
    }
    .card-4{
      grid-area: auto;
      top: 0;
    }
    .card-5{
      grid-area: auto;
      top: 0;
    }
   }
  }
  