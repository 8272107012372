.popup-wrapper {
  position: fixed;
  height: fit-content;
  top: -10000px;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 24px;
  pointer-events: auto !important;
  transition: all 0.25s ease-in-out;
  overflow-x: hidden;
  width: 33%;

  @media screen and (max-width: 1440px) {
      width: 40%;
  }
  @media screen and (max-width: 1300px) {
      width: 50% !important;
  }
  @media screen and (max-width: 786px) {
      width: 90% !important;
  }
}

.popup-active {
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
  top: 0% !important;
}

.popup-title {
  font-weight: 700;
  font-size: 24px;
  // line-height: 48px;

  @media screen and (max-width: 786px) {
    font-size: 15px;
  }
}

.close-wrapper {
  position: relative;
  top: -15px;
  right: -10px;
}

.popup-content {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #554fd8;
}

.popup-span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.018em;
  text-align: center;
}

.basepopup {
  object-fit: contain;
}

.land-nft-image{
  width: 500px;
  height: 260px;

}

.gameonbase{
  width: 427px;
  height: 320px;

}

.unit {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border-radius: 8px;
  padding: 10px 15px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(106, 17, 203, 0.5);
  width: 80px;
}

.time-big-number {
  font-size: 24px;
  font-weight: bold;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6);
  margin: 0;
color: white;
}

.time-small-number {
  font-size: 12px;
  margin-top: 5px;
  display: block;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.timer-separator {
  font-size: 24px;
  color: #6a11cb;
}

@media screen and (max-width: 786px) {
    .popup-span{
        width: 100%;
        font-size: 13px;
    }

  //   .land-nft-image{
  //     width: 75% !important;
  // }
}

.popup-title-wrapper{
  border-radius: 56px;
background: linear-gradient(0deg, #EFF0F6 0%, #EFF0F6 100%);
}