.touch-wrapper {
  min-height: 500px;
  background: linear-gradient(0deg, #eff0f6 0%, #ffffff 73.43%);
  border-radius: 0px 0px 56px 56px;

  @media screen and (max-width: 786px) {
    border-radius: 0 0 28px  28px;
  }

  .general-container {
    background: #ffffff;
    border: 2px solid #75cac2;
    border-radius: 12px 0px;

    .touch-title {
      background: linear-gradient(138.68deg, #a6e1dc 7.17%, #75cac2 67.7%);
      border-radius: 12px 0px;
      width: fit-content;
      padding: 7px 16px;
      position: absolute;
      top: -15px;
      left: -2px;
    }

    .telegram {
      position: absolute;
      bottom: 15px;
      left: 15px;
      transition: all .5s;
      -webkit-transition: all .5s;
      border-radius: 0 0 12px 0px;
      box-shadow: 0px 22px 44px rgba(36, 161, 222, 0.32);
    }
    .discord {
      position: absolute;
      right: 15px;
      top: 15px;
      transition: all .5s;
      -webkit-transition: all .5s;
      border-radius: 0 0 12px 0px;
      box-shadow: 0px 22px 44px rgba(88, 101, 242, 0.32);
    }

    .telegram:hover,
    .discord:hover{
      transform: translateX(5px) translateY(-5px);
      -webkit-transform: translateX(5px) translateY(-5px);

    }

  }
  .touch-socials {
    grid-template-columns: repeat(4, 1fr);
    background: #ffffff;
    border: 2px solid #f59979;
    border-radius: 12px 0px;
    gap: 20px;
    justify-content: center;
    align-items: end;
    padding: 35px 25px;

    .socials-title {
        background: linear-gradient(106.2deg, #F59979 12.72%, #F8845B 50.47%);
        border-radius: 12px 0px;
        width: fit-content;
        padding: 7px 16px;
        position: absolute;
        top: -15px;
        left: -2px;
      }

    .social-title{
        text-align: center;
    font-size: 12px;
    color: #F8845B;
    }
  }
}


@media screen and (max-width: 1400px) {
    .telegram,
    .discord{
      width: auto !important;
    }  
}


@media screen and (max-width: 786px) {

    .touch-socials{
        grid-template-columns: repeat(2,1fr) !important;
    }

    .general-container{
      display: flex;
      flex-direction: column;
      min-height: 300px !important;
      justify-content: center;
      gap: 20px;
    }

   .discord,
   .telegram{
    position: unset !important;
    width: 250px;
    height: 95px;
   }
}
