.gov-main-wrapper {
    background: url(https://cdn.worldofdypians.com/dypius/bg-gov.webp), linear-gradient(0deg, #EFF0F6 0%, rgba(239, 240, 246, 0) 73.43%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 17rem;
    border-radius: 0px 0px 56px 56px;
    overflow-x: hidden;
    
  

    @media only screen and (max-width: 991px) {
        background-size: auto;
        border-radius: 0px 0px 28px 28px;
        padding-bottom: 24rem;

     
       } 
    
}

.purplelink {
    position: absolute;
    top: -137px;
    z-index: -1;
    @media only screen and (max-width: 991px) {
     display: none;
  
    } 
  
  }

  .purplelinkmd {
    display: none;

    @media only screen and (max-width: 991px) {
        display: block;
        position: absolute;
        top: -37px;
        z-index: -1;
        height: 700px;
       } 
  }