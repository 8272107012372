@-webkit-keyframes comet-fall {
  0% {
    -webkit-transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(250px, 930px);
  }
}

@keyframes comet-fall {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(250px, 930px);
  }
}

.comet {
  position: absolute;
  top: 10%;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: comet-fall;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: comet-fall;
  animation-duration: 2s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;

  img {
    scale: 2.5;
  }
}
.comet:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.comet:nth-of-type(1) {
  left: 1%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}
.comet:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 3s, 0.5s;
  animation-delay: 3s, 0.5s;
}
.comet:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.comet:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}
.comet:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 4s, 3s;
  animation-delay: 4s, 3s;
}
.comet:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 3s, 2s;
  animation-delay: 3s, 2s;
}
.comet:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.comet:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}
.comet:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}

.comet:nth-of-type(10) {
  left: 90%;
  -webkit-animation-delay: 3s, 1s;
  animation-delay: 3s, 1s;
}


@media only screen and (max-width: 500px) {
  .comet {
    animation: none !important;
    -webkit-animation: none !important;

  }

  .comet:nth-of-type(2) {
    top: 141px !important;
  }
  
  .comet:nth-of-type(3) {
    left: 30% !important;
    top: 440px !important;
  }

  .comet:nth-of-type(5) {
    left: 14% !important; 
    top: 600px !important;
}

.comet:nth-of-type(6) {
  left: 22% !important;
}
.comet:nth-of-type(7) {
  left: 58% !important; 
}
}