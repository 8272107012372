.dypTokenomics {
  bottom: 55px !important;

  @media screen and (max-width: 786px) {
     bottom: 35px !important;
  }
}
.iDypTokenomics {
  bottom: 210px !important;

  @media screen and (max-width: 786px) {
    bottom: 300px !important;
  }
  @media screen and (max-width: 500px) {
    bottom: 165px !important;
  }
}

.dyp-sc-address {
  cursor: pointer;
}

.bottomitems {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.018em;
  color: #554fd8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: pointer;
}

.bottomitems:hover {
  text-decoration: underline;
}

.tooltip {
  background: #EEEDFB !important;
border: 1px solid rgba(85, 79, 216, 0.1);
box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.2);
border-radius: 8px;
position: absolute;
left: 0;
top: -70px;
width: 200px;
}
.tooltip-tokenomics {
  right: 35px;
  top: -25px;
}

.dypborder {
  border: 2px solid #756EDF;
  border-radius: 18px 0px;
}

.tokenomics-wrapper {
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 18px 0px;

  .tokenomics-icon {
    background: #7770e0;
    border-radius: 18px 0px;
    padding: 10px;
  }
}

.circulating-wrapper {
  background: rgba(85, 79, 216, 0.1);
  border-radius: 12px 0px;
  padding: 20px;
  width: fit-content;

  .circulating-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: rgba(119, 112, 224, 0.6);
  }

  .circulating-amount {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #554fd8;
    text-align: center;
  }

  .email {
    display: none;
  }
}

.legenditem {
  @media only screen and (max-width: 500px) {
    align-items: center;
    text-align: center;
  }
}
.circulating-wrapper:hover {
  .email {
    display: block;
  }
}

.headertitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #554fd8;
  padding-left: 0;
}
.dypcontract-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #9593a3;
}

.legendTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #acacac;
}

.legendDesc {
  font-weight: 600;
font-size: 22px;
line-height: 33px;
letter-spacing: -0.01em;
display: flex;
align-items: center;
gap: 10px;
}

.dypcontract-addr {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1e2029;
  gap: 10px;
  img {
    cursor: pointer;
  }

  @media screen and (max-width: 786px) {
    font-size: 15px;
  }
}

.mininginfo-wrapper {
  background: #ffffff;
  border: 4px solid rgba(85, 79, 216, 0.1);
  border-radius: 12px 0px;
  padding: 16px;
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #554fd8;
  }
  li{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #554fd8;
  }
}

.card-body {
  top: -14px;
  border: none;
  height: 100%;
}

.legendborder {
  border: 4px solid #F5F6FA;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 28px;
  padding: 5px;
}

.legendwrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;

  @media only screen and (max-width:1199px){
    grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  }
}

.separator2 {
  background: linear-gradient(90deg, #7770E0 0%, rgba(119, 112, 224, 0) 100%);
  height: 1px; 
  margin-top: 10px;
  margin-bottom: 10px;
}

.outer-wrapper {
  position: relative;
  width: 100%;
  left: 0px;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
}

@media screen and (max-width: 1400px) {
  .tokenomics-wrapper {
    // margin-top: 25px !important;
  }

  .tokenomics-btn {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 786px) {
  .tokenomics-btn {
    width: 100% !important;
  }

  .outer-wrapper {
    margin-top: 35px;
    bottom: 0;
  }

  .email {
    display: block !important;
  }
}
