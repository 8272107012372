.faq-wrapper {
  min-height: 700px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 1000px;

  .faq-container {
    background: #ffffff;
    border: 6px solid #7670e0;
    box-shadow: 0px 24px 60px rgba(85, 79, 216, 0.25);
    border-radius: 56px 0px;

    @media screen and (max-width: 786px) {
      border-radius: 28px 0;
    }

    .form-select {
      font-size: 14px;
      border: 1px solid #a0a3bd;
      border-radius: 8px;
      color: #a0a3bd;
      z-index: 3;
    }

    h2 {
      color: #7670e0;
    }
  }

  .categories-container {
    background: linear-gradient(0deg, #eff0f6 0%, #ffffff 73.43%);
    border-radius: 0px 0px 56px 56px;
    min-height: 500px;

    .search-container {
      background: #ffffff;
      box-shadow: 0px 24px 60px rgba(85, 79, 216, 0.25);
      border-radius: 28px 0px;
      height: 100px;

      @media screen and (max-width: 786px) {
        width: 100% !important;
      }
    }

    .categories-grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      @media screen and (max-width: 912px) {
        grid-template-columns: repeat(4, 1fr);
      }


      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .category-card {
        background: #ffffff;
        border: 2px solid #e9eaf0;
        box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.08);
        border-radius: 28px 0px;
        width: 120px;
        height: 120px;
        cursor: pointer;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;


        p {
          color: #7670e0;
        }
      }

      .category-card:hover {
        border: 2px solid #554fd8;
      }

      .active-category {
        background: linear-gradient(
          135deg,
          #7770e0 0%,
          #554fd8 100%
        ) !important;
        box-shadow: 0px 24px 60px rgba(85, 79, 216, 0.25) !important;
        border-radius: 28px 0px !important;
        p {
          color: #f7f7fc !important;
        }
        border: 2px solid transparent
      }

   
    }

    .faq-sphere {
      position: absolute;
      width: 180px;
      right: -100px;
      bottom: 0;
    }

    .search-items{
      background: #F7F7FC;
      box-shadow: 12px 16px 44px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      display: grid;
      grid-template-columns: auto;
      position: absolute;
      top: 50px; 
      @media screen and (max-width: 786px) {
        width: 100% !important;
      } 

      .search-item{
        cursor: pointer;
        transition: all .3s;
        -webkit-transition: all .3s;

      }

      .search-item:hover{
        background-color: #E4E2F9;
      }

    }
  }
}


@media screen and (max-width: 1400px) {
  .faq-sphere{
    bottom: -70px !important;
  }
}


