
  .branditem {
    background: linear-gradient(180deg, #EFF0F6 0%, #EFF0F6 100%);
    border-radius: 28px 0px;
    padding: 20px;

    .brand-icon{
      background: #D7D6F2;
      border-radius: 50%;
      padding: 14px;
      
    }
    }

    .brand-desc {
      font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(30, 32, 41, 0.6);
  }

  .brand-ring{
    position: absolute;
    left: -10%;
    top: 60%;
  }