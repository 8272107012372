.scrollTop {
    position: fixed;
    // width: 100px;
    right: 0px;
    bottom: 90px;
    align-items: center;
    // height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    -webkit-animation: fadeIn 0.3s;

    transition: opacity 0.4s;
    -webkit-transition: opacity 0.4s;

    opacity: 0.5;
  }
  
  .scrollTop:hover {
    opacity: 1;
  }


  @media screen and (max-width: 786px) {
    .scrollTop{
      bottom: -15px;
    }
  }