.audited-by-section {
    display: flex;
    margin-bottom: 32px;




    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 90px;
        padding-right: 16px;
        gap: 5px;
        

        img {
            // width: 43px;
            // height: 43px;
        }
        // margin-right: 16px;
        &:last-of-type {
            border-right: 0;
        }
        &-text {
            font-weight: 400;
            font-size: 9px;
            line-height: 14px;
            color: rgba(119, 112, 224, 0.8);
            margin: 0;
            text-align: center;
            

        }
    }
}

a {
    text-decoration: none !important;
}