.uni-card {
  width: 290px;
  position: absolute;
  right: 0;
  top: 60px;
  transition: all .5s;
  -webkit-transition: all .5s;

}

.mexc-card{
  width: 220px;
  position: absolute;
  left: 50px;
  top: 65px;
  box-shadow: 0px 22px 44px rgba(24, 119, 242, 0.32);
  border-radius: 10px 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;

}

.pancake-card {
  width: 290px;
  position: absolute;
  left: 32px;
  top: 170px;
transition: all .5s;
  -webkit-transition: all .5s;

}

.pangolin-card {
  width: 230px;
  position: absolute;
  left: 315px;
  top: 195px;
  transition: all 0.5s;
  border-radius: 10px 0;
  box-shadow: 0px 22px 44px rgba(17, 17, 17, 0.32);
  -webkit-transition: all 0.5s;

}

.uni-card:hover,
.pancake-card:hover,
.pangolin-card:hover,
.mexc-card:hover{
  transform: translateX(5px) translateY(5px);
  -webkit-transform: translateX(5px) translateY(5px);

}



.buyidypsection {
  position: relative;
  min-height: 430px;
  width: 100%;
  justify-content: space-between;
  // overflow: hidden;
}

.buyidypsection::before {
  content: "";
  background-image: url("https://cdn.worldofdypians.com/dypius/buyidypbg.png"),
  linear-gradient(0deg, #EFF0F6 0%, #FFFFFF 73.43%);
  background-size: cover;
  background-position: bottom;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  // opacity: 0.2;
  border-radius: 0px 0px 56px 56px;
}

@media screen and (max-width: 1200px) {
  .marketplace-container{
    .mexc-card{
      left: -70px;
    }
    .uni-card{
      right: -15px;
    }
    .pancake-card{
      left: -30px;
    }
    .pangolin-card{
      left: 210px;
    }
  }
}



@media only screen and (max-width: 991px) {
 .marketplace-container{

  display: grid;
  gap: 25px;

  .uni-card,
  .pancake-card,
  .pangolin-card,
  .mexc-card {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    width: 350px;
  }

  .pangolin-card,
  .mexc-card{
    top: 20px;
    width: 270px;
    left: 40px;
  }


 }
  #ourTokens{
    // top: -140px !important;
    margin-bottom: 130px;
  }

  .buyidypsection{
    padding-bottom: 120px;
  }

.buyidypsection::before {
  border-radius: 0px 0px 28px 28px;

}

}


@media only screen and (max-width: 500px) {
  #ourTokens{
    // top: -140px !important;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 375px) {
  .uni-card,
  .pancake-card,
  .pangolin-card,
  .mexc-card {
    position: relative;
    left: -15px;
    right: auto;
    top: auto;
  }



}

