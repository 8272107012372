.proposals-wrapper {
  margin-top: -245px;
  margin-bottom: 50px;



  .total-proposals-wrapper{
    position: absolute;
    right: 0;
    top: -105px;
    .total-proposals{
      background: #ffffff;
      border-radius: 12px;

      .total-votes-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #1E2029;
        
      }
      .total-votes-amount{
        font-weight: 300;
font-size: 28px;
line-height: 42px;
text-align: right;
color: #7770DF;

      }

      .proposal-span {
        background: #7770E0;
        height: 40px;
        width: 5px;
        position: absolute;
        left: 0;
        top: 17px;
        border-radius: 0 5px 5px 0;
  
        @media screen and (max-width: 786px) {
          top: 15px;
        }
  
      }
    }
  }

  .proposal-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .proposal-card {
      background: #ffffff;
      border-radius: 28px 0px 28px;
      box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      height: fit-content;
      .chain {
        font-weight: 600;
        font-size: 18px;
      }
      .proposals {
        font-weight: 600;
        font-size: 64px;
        color: #7770e0;
      }
      .total {
        font-size: 12px;
        font-weight: 400;
        color: #ada9ec;
      }

      .chain-icon {
        position: absolute;
        top: -15px;
        width: 35px;
      }
    }
  }


  .gray-box-large{
    width: 118px;
border-radius: 12px;
height: 34px;

background: #EFF0F6;
  }

  .gray-box-small{
    width: 61px;
height: 34px;
border-radius: 12px;
background: #EFF0F6;
  }
  .recent-proposals {
    background: #ffffff;

    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 12px 0px;
    margin-bottom: 9px;
    .active-pools {
      padding: 5px 13px;
      color: #57b6ab;
      background: #caf7f3;
      border-radius: 6px;
    }
    .inactive-pools {
      padding: 5px 13px;
      color: #f7f7f7;
      background: #AAA7EC;
      border-radius: 6px;
    }
    .proposal-span {
      background: #7770E0;
      height: 40px;
      width: 5px;
      position: absolute;
      left: 0;
      top: 20px;
      border-radius: 0 5px 5px 0;

      @media screen and (max-width: 786px) {
        top: 15px;
      }

    }

    .recents-container {

      .slick-list{
        padding-bottom: 0.5rem;
      }
      .burn {
        padding: 5px 13px;
        color: #f0603a;
        background: #FFF3EF;
        border-radius: 12px;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
      }
      .expiry-text {
        font-size: 10px;
        font-weight: 400;
      }
      .expiry-date {
        font-size: 14px;
        font-weight: 600;
        color: #7770e0;
      }
      
    }
  }
}


@media screen and (max-width: 786px) {
  .proposals-wrapper{
    .proposal-container{
      gap: 10px !important;
      margin-bottom: 15px;
      position: relative;
    bottom: 20px;
      .proposal-card{
        width: 115px;
        height: 165px;
        .proposals{
          font-size: 45px;
        }
        .chain{
          font-size: 13px;
        }
      }
    }
  }
  
}