.roadmap-wrapper {
  min-height: 800px;
  position: relative;
  background: linear-gradient(180deg, #7770e0 0%, #554fd8 100%);
  border: 6px solid #eff0f6;
  border-radius: 56px 0px;
  margin-top: 100px;

  .slick-slider{
    top: -30px;
  }

  .years-wrapper {
    background: #eff0f6;
    border-radius: 8px;
    z-index: 9;

    p {
      color: #6e7191 !important;
      margin-bottom: 0;
      cursor: pointer;
      font-size: 12px;
      padding: 5px 15px;
    }

    .selected-year {
      background: #5953d9;
      border-radius: 8px;
      color: white !important;
    }
  
  @media screen and (max-width: 786px) {
    flex-wrap: wrap;
  }
  }




  .roadmap-title {
    p {
      color: rgba(247, 247, 252, 1);
      font-weight: 400;
      font-size: 16px;
    }
  }

  .date-card {
    .outer-circle {
      width: 32px;
      height: 32px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      z-index: 1;

      .inner-circle {
        width: 16px;
        height: 16px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
        z-index: 2;
      }
    }

    h2 {
      color: rgba(247, 247, 252, 0.6);
    }
  }

  .selected {
    .outer-circle {
      background-color: rgba($color: #f57c53, $alpha: 0.3);
      .inner-circle {
        background-color: rgba($color: #f57c53, $alpha: 1);
      }
    }

    h2 {
      color: #ffffff !important;
      opacity: 1;
    }
  }

  .roadmap-items {
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 28px 0px;
    background: #625cd9;

    p {
      color: #f7f7fc;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 0 !important;
    }

    .roadmap-accordion {
      background: #625cd9 !important;
      // padding-left: 0 !important;
      // padding-right: 0 !important;
      padding: 5px !important;
      outline: none !important;
      cursor: pointer;
    }
    .roadmap-accordion-white {
      background: inherit !important;
      // padding-left: 0 !important;
      // padding-right: 0 !important;
      padding: 5px !important;
      outline: none !important;
      cursor: pointer;
    }
    .roadmap-accordion::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

    .roadmap-list {
      background: #625cd9 !important;
    }

    .roadmap-list li {
      color: #f7f7fc;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 0 !important;
    }
    .accordion-list-item {
      font-weight: 400;
      font-size: 12px;
    }
  }

  hr {
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 53.13%,
      rgba(255, 255, 255, 0) 101%
    );
    opacity: 0.6;
    border-radius: 50px;
    height: 4px;
    position: absolute;
    top: 55px;
  }
  .left-button {
    width: 42px;
    height: 42px;
    background: rgba(255, 255, 255);
    border-radius: 8px 0px;
    // opacity: 0.5;
    z-index: 5;
    position: absolute;
    top: 35px;
    left: 5.5%;
  }

  .right-button {
    width: 42px;
    height: 42px;
    border-radius: 0px 8px;
    border: 2px solid #ffffff;
    background: linear-gradient(180deg, #7770e0 0%, #554fd8 100%);

    // opacity: 0.5;
    z-index: 5;
    position: absolute;
    top: 35px;
    right: 5.5%;
  }
  .enabled {
    opacity: 1 !important;
    cursor: pointer;
  }

  .highlight {
    background: #ffffff;
    box-shadow: 0px 12px 40px rgba(27, 25, 79, 0.24);
    p {
      color: #6e7191 !important;
    }
  }

  .carousel-wrapper {
    .slick-list {
      width: 1200px;
      // padding-left: 15px;
      padding-top: 25px;
    }

    .selected-roadmap {
      top: -24px !important;
    }
  }
}

.roadmap-wrapper::before {
  content: "";
  background-image: url("https://cdn.worldofdypians.com/dypius/roadmapBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 150px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.5;
}

.to-roadmap {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #f7f7fc;
}

.to-roadmap-wrapper {
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}
.purplediv {
  width: 4px;
  height: 45px;
  position: absolute;
  background: linear-gradient(280.88deg, #48C7C4 9.02%, #53E4E1 90.66%);;
  border-radius: 0px 100px 100px 0px;
  left: 0px;
}

.roadmap-desc-wrapper {
  background: #4944ae;
  border-radius: 28px 0px;
  opacity: 1 !important;
  width: 75%;
  @media screen and (max-width: 786px) {
    width: 100%;
  }

  .roadmap-desc {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 24px;
    color: #f7f7fc;
  opacity: 1 !important;

  }
}
.no-visibility{
  visibility: hidden;
  @media screen and (max-width: 786px) {
    visibility: visible !important;
    display: none !important;
  }
}

.hidden-roadmap{
  visibility: hidden !important;
}

@media screen and (max-width: 1399px) {
  .carousel-wrapper {
    .slick-list {
      width: 1120px !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .carousel-wrapper {
    .slick-list {
      width: 945px !important;
    }
  }
}

@media screen and (max-width: 786px) {
  .roadmap-wrapper {
    min-height: 700px;

    hr {
      top: 46px;
    }
  }

  .carousel-wrapper {
    .slick-list {
      width: 390px !important;
    }
  }
}

.accordion-item {
  border: none !important;
}
