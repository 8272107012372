.dyp-tools {
    position: relative;
    min-height: 500px;
    width: 100%;
    justify-content: space-between;
    // overflow: hidden;
  
    .col-4 {
      gap: 40px;
    }
  
    .list-card {
      column-gap: 15px;
      p {
        font-size: 14px;
      }
    }
  
    .iphoneBackground {
      top: 0;
      height: 430px;
    }
  
    .iphone {
      position: absolute;
      height: 190px;
      top: 160px;
    }
  
    .dots2 {
      position: absolute;
      top: 90%;
      right: 5%;
    }

    .leftcol {
     @media only screen and (max-width: 500px) {
      padding-left: 0 !important;
     }
    }
  }
  
  .dyp-tools::before {
    content: "";
    background: #eff0f6;
    background-image:
     url("https://cdn.worldofdypians.com/dypius/dyptools-bg.png"),
      linear-gradient(#caccd9, #fff);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    // opacity: 0.2;
    border-radius: 56px 0 0 0;
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    -webkit-transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  

.rotate-center {
	-webkit-animation: rotate-center 8s ease-in-out infinite both;
	        animation: rotate-center 8s ease-in-out infinite both;
}

 @-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.triangle {
  position: absolute;
  top: 30px;
  right: 70px;
  width: 120px;
  // animation: diagonal 5s ease infinite
}

@keyframes diagonal {
  0%{
    transform: translateX(0px) translateY(0px);
  }
  50%{
    transform: translateX(8px) translateY(-8px);
  }
  100%{
    transform: translateX(0px) translateY(0px);
  }
}

@-webkit-keyframes diagonal {
  0%{
    -webkit-transform: translateX(0px) translateY(0px);
  }
  50%{
    -webkit-transform: translateX(8px) translateY(-8px);
  }
  100%{
    -webkit-transform: translateX(0px) translateY(0px);
  }
}


  @media only screen and (max-width: 500px) {
    .triangle {
          top: 20px !important;
    right: 0px !important;
    }

    .iphoneBackground {
      height: 340px !important;
      margin-top: 20px !important;
    }

    .dyp-tools{
      .iphone{
        top: 65px;
      }
    }
   }


   @-webkit-keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(1.2) rotateZ(180deg);
              transform: scale(1.2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }
  @keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(1.2) rotateZ(180deg);
              transform: scale(1.2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }


  @keyframes pulse{
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(1.1);
    }
    100%{
      transform: scale(1);
    }
  }

  @-webkit-keyframes pulse{
    0%{
      -webkit-transform: scale(1);
    }
    50%{
      -webkit-transform: scale(1.1);
    }
    100%{
      -webkit-transform: scale(1);
    }
  }
  

  .rotate-scale-up {
    // -webkit-animation: rotate-scale-up 25s linear infinite both;
            // animation: rotate-scale-up 25s linear infinite both;
    background: url(https://cdn.worldofdypians.com/dypius/coinbackground.png);
    background-repeat: no-repeat;
    max-width: 400px;
    width: 100%;
    height: 400px;
    background-size: contain;
  }


  .pulse{
    -webkit-animation: pulse 5s linear infinite both;
    animation: pulse 5s linear infinite both;
background: url(https://cdn.worldofdypians.com/dypius/coinbackground.png);
background-repeat: no-repeat;
max-width: 400px;
width: 100%;
height: 400px;
background-size: contain;
  }