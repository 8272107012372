.about-main-hero {
  min-height: 500px;
  background: linear-gradient(0deg, #eff0f6 0%, rgba(239, 240, 246, 0) 73.43%);
  border-radius: 0px 0px 56px 56px;
  overflow: hidden;
  .painting-container {
    position: absolute;
    top: 75%;
    left: 20%;
    width: 290px;
    height: 204px;
    background: #f7f7fc;
    box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    z-index: 2;
  }


  .about-collage{
    position: relative;
    top: 35px;
  }

  .bull {
    position: absolute;
    z-index: 4;
    top: 35%;
    left: 28%;
  }


  .flower {
    position: absolute;
    z-index: 3;
    top: 35%;
    left: 25%;
  }

  .bluehero {
    position: absolute;
    left: 33%;
    top: 25%;
    opacity: 0.7;
    filter: blur(40px);
    z-index: 1;
  }

  .submenu-link {
    display: flex;
    justify-content: center;
    width: 140px;
    color: rgba(85, 79, 216, 1);
    gap: 9px;
    font-size: 15px;

        @media screen and (max-width: 786px) {
          justify-content: space-between;
          width: 150px;
        }
    .submenu-indicator{
        animation: pulse 1.5s linear infinite;
        -webkit-animation: pulse 1.5s linear infinite

    }
  }
  
  .image-container{
    min-height: 200px;
    min-width: 200px;
  }
}

@keyframes pulse{
    0%{
      transform: scale(1);
    }
  
    50%{
      transform: scale(1.4);
    }
  
    100%{
      transform: scale(1);
    }
  }

  @-webkit-keyframes pulse{
    0%{
      -webkit-transform: scale(1);
    }
  
    50%{
       -webkit-transform: scale(1.4);
    }
  
    100%{
       -webkit-transform: scale(1);
    }
  }
  

@media screen and (max-width: 1200px) {
  .about-main-hero{
    .painting-container{
      top: 100%;
    }
    .bull,
    .flower{
      top: 60%;
    }
   
  }
}


@media screen and (max-width: 786px) {


  .about-collage{
    width: 375px;
  }

  .image-container {
    min-height: 250px !important;

    .bull {
      height: 200px !important;
      top: 25%;
    }

    

    .painting-container {
      width: 173px;
      height: 123px;
      bottom: 0%;
      left: 15%;
      top: 45%;

      .painting {
        height: 110px !important;
      }
    }
    .flower {
      height: 130px;
      top: 25%;
    }

    .bluehero {
      height: 250px;
      top: 15% !important;
      left: 40% !important;
      filter: blur(25px);
    }
  }

  .submenu-container{
    display: grid;
    grid-template-columns: auto auto;
  }
}
