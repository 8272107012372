.chainlink-wrapper {
  background: #eff0f6;
  border-radius: 0px 0px 56px 56px;
  min-height: 215px;
  display: flex;
  align-items: end;
  padding-bottom: 20px;
  // z-index: -1;
  position: relative;
  bottom: 75px;
  padding-bottom: 35px;

  @media only screen and (max-width: 1399px) {
    bottom: 25px !important;
  }

  .audit{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.chainlink-data-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #6059da;
}

.chainlink-cell {
  background: linear-gradient(
    90deg,
    rgba(119, 112, 224, 0.3) 0%,
    rgba(119, 112, 224, 0) 89.29%
  );
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.chainlink-row {
    gap: 35px;
}

.totalpaid-amount {
 font-weight: 300;
font-size: 26px;
line-height: 39px;
letter-spacing: 0.05em;
color: #7770E0;
}

.totalpaid-token {
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 27px;
letter-spacing: 0.05em;
color: #7770E0;
}

.pin {
  position: absolute;
  left: -40px;
}


@media screen and (max-width: 786px) {
  .chainlink-wrapper{
    bottom: 20px;
    padding-bottom: 50px;
  }
  
  .audit{
    margin-left: 10px;
  }
  .pin{
    left: -25px;
  }
  
}