
.dypTokenomics{
  bottom: 55px !important;

  @media screen and (max-width: 786px) {
    bottom: 200px !important;
  }
}



.idyp-token-wrapper{
  bottom: 80px;
  @media screen and (max-width: 786px) {
    bottom: 0;
  }
}

.ourtokens-wrapper {

  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 56px 0px;
  padding: 3rem;
  min-height: 653px;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;

  @media only screen and (max-width: 991px) {
    padding: 1rem 6px 1rem 1rem;
    border-radius: 28px 0px;
  }

  .ourtoken-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #ffffff;
    display: grid;

    @media only screen and (max-width: 500px) {
      font-size: 40px;
      text-align: center;
    }
  }

  .ourtoken-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);

    @media only screen and (max-width: 500px) {
      text-align: center;
    }
  }

  .tokengraph {
    position: absolute;
    right: 0;
    padding: 0;
    top: -50px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);


    @media only screen and (max-width: 991px) {
      right: auto;
    top: auto;
    }
  }
}

.ourtoken-sphere {
  position: absolute;
  bottom: 0;
  left: -13%;
  z-index: -1;
}

.blueheroidyp {
  filter: blur(40px);
  position: absolute;
  z-index: -1;
  left: -250px;
  top: 465px;
}