.tokenwrapper {
  background: #fff;
  border-radius: 12px;
  padding: 16px 16px 8px 16px;
  max-width: 325px;
  height: fit-content;
  @media only screen and (max-width: 991px) {
    max-width: inherit !important;
    width: 100%;
  }

  .tokentitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.018em;
    color: #5650d8;
  }

  .tokenprice {
    font-weight: 300;
    font-size: 38px;
    line-height: 57px;
    letter-spacing: -0.018em;
    color: #7770e0;

    @media only screen and (max-width: 500px) {
      font-size: 34px;

    }
  }

  .token-valuechange {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.018em;
  }

  .tokenpercentage {
    border-radius: 6px;
    // padding: 10px;
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.018em;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }
  }

  .current-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(119, 112, 224, 0.6);
    position: relative;
    bottom: 8px;
  }
}

.tokenwrapper:nth-child(1) {
  position: relative;
  top: 20px;

  @media only screen and (max-width: 1199px) {
    top: auto !important;
    margin-bottom: 2rem;
  }
}

.tokenwrapper:nth-child(2) {
    position: relative;
    top: 35px;

    @media only screen and (max-width: 1199px) {
      top: auto !important;
    }
  }
