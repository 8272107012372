.bridge {
  background-color: #f16640;
  border-radius: 18px 0px;
  width: 47%;
  padding: 0;
}

.bgbridge {
    background: url("https://cdn.worldofdypians.com/dypius/bridgebgProduct.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 20px;
    mix-blend-mode: initial;
    border-radius: 18px 0px;
  }

.earn {
  background: linear-gradient(
      180deg,
      rgba(117, 202, 194, 0.3) 0%,
      rgba(87, 182, 171, 0.3) 100%
    );
  border-radius: 18px 0px;
 width: 43%;
  padding: 0;

}

.bgearn {
    background: url("https://cdn.worldofdypians.com/dypius/earnbgProduct.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 20px;
    // mix-blend-mode: luminosity;
    border-radius: 0 0 18px;

  }


.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #fff;
}

.desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);

  max-width: 330px;
}

.linkdesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
}

.linkdesc:hover {
  color: #fff;
  text-decoration-line: underline !important;

}


@media only screen and (max-width: 991px) {
  .earn, .bridge {
    width: 100% !important;
  }

  .desc {
    max-width: 100%;
  }
}