.eduactionalwrapper {

    // background: linear-gradient(360deg, #EFF0F6 0%, rgba(239, 240, 246, 0) 65.48%);
    border-radius: 56px 0px;
    min-height: 620px;
    position: relative;
    bottom: 60px;

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(30, 32, 41, 0.6);
  }


  
.educational-wrapper{
  display: grid;
  grid-template-columns: repeat(2,1fr);

  @media screen and (max-width: 786px) {
    grid-template-columns: auto;
  }
  
  .cardWrapper {
    border-radius: 12px 0px;
    position: relative;
    transition: all .5s;
    -webkit-transition: all .5s;
    box-shadow: 0 32px 64px hsla(0, 0%, 7%, .08);
  }

  .cardWrapper:hover{
    transform: translateX(5px) translateY(-5px);
    -webkit-transform: translateX(5px) translateY(-5px);
  }

  .cardWrapper:nth-child(odd){
  top: 40px !important;
  
  @media screen and (max-width: 786px) {
    top: 0 !important;  
  }

  }



  .eduImg {
    border-radius: 12px 0px;
    height: auto;
    object-fit: cover;
    width: 100%;
    

    @media only screen and (max-width: 576px) {
        height: auto !important;
        // width: 125px; 
    }
  }

  .purpleArrow {
    position: absolute;
    right: 0;
    bottom: -10px;
  }
}


}



@media screen and (max-width: 576px) {

  .eduactionalwrapper{
    min-height: auto;
  }

  

  .cardWrapper{
    width: 100% !important;
  }
} 

.edu-sphere {
  position: absolute;
  right: 55%;
  top: -10%;
  z-index: 0;
}

.edu-sphere2 {
  position: absolute;
  right: -20%;
  bottom: -80px;
  z-index: 0;
  width: 200px;
  height: 200px;

}
