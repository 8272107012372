.supportedwallet-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: linear-gradient(360deg, #554FD8 0%, #756EDF 98.32%);
    border: 6px solid #EFF0F6;
    border-radius: 56px 0px;


  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 64px;
    color: #f7f7ff;
  }
}

.dypcontractaddr {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #f7f7fc;
}

.contractaddr {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
  display: flex;
    gap: 5px;
    align-items: center;
}

.walletitem {
    border-radius: 12px 0px;
    border: 2px solid #7C77E1;
    padding: 10px;
    width: 238px;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
}

.copiedtxt {
  font-size: 8px;
}


.wallettitle {
    font-weight: 500;
font-size: 24px;
line-height: 64px;
color: rgba(247, 247, 255, 0.9);
}


@media only screen and (max-width: 500px) {


  .dypaddr-wrapper {
    text-align: center;
  }
}